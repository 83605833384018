import { graphql } from "gatsby";
import * as React from "react"
import { useTranslation } from "react-i18next"
import DefaultLayout from "../../layout";

import * as styles from "../index.module.scss"

const ImprintPage = () => {
  const {t} = useTranslation();

  return (
    <DefaultLayout title={t("imprint")}>
      <div className={styles.layoutBox}>
        <h1>{t("imprint")}</h1>
        <p>Steffen Rüting<br/>Alte Landstraße 10a<br/>25474 Hasloh</p>
        <b>{t("imprintContact")}</b>
        <p>{t("imprintPhone")}: +49 4106 8068004<br/>{t("imprintMail")}: <a href="mailto:contact@beambot.top">contact@beambot.top</a></p>
      </div>
    </DefaultLayout>
  )
}

export default ImprintPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
